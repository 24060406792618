// Utils
import { get, unionBy, isEmpty } from 'lodash';

export default {
    props: {
        event: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            visible: false,
            collapsed: false,
            uploads: [],
            started: false,
            activeUploads: 0,
            uploadedFiles: 0,
            totalUploadsCount: 0
        };
    },

    computed: {
        /** @returns {boolean} */
        stillUploading() {
            return this.activeUploads > 0;
        }
    },


    methods: {
        /**
         * @param {MessageEvent} event
         */
        async handleWorkerMessage(event) {
            const response = event.data;
            const eid = this.event.id || this.event._id;
            const messageEventId = this.findEvendId(response);

            if (eid !== messageEventId) {
                console.debug(`[${this.$options.name}] Skipping event originated from another workspace`);
                return;
            }

            switch (response.type) {
                case 'status': {
                    this.onStatus(response);
                    break;
                }

                case 'result': {
                    this.onResult(response);
                    break;
                }

                case 'error': {
                    this.onError(response);
                    break;
                }

                case 'canceled': {
                    this.onCanceled(response);
                    break;
                }

                case 'info': {
                    this.onInfo(response);
                    break;
                }
            }
        },

        /**
         * Status message handler
         *
         * @param {object} response the worker message
         */
        onStatus(response) {
            const data = response[response.type];
            if (data === 'start') {
                this.$store.dispatch('updateUploadStatus', true);
            }

            if (data === 'done') {
                if (!this.stillUploading) {
                    if (typeof this.onUploaded === 'function') {
                        this.onUploaded(response);
                    } else {
                        this.$emit('terminate', 'completed');
                    }
                }
            }

            if (data === 'clean') {
                this.reset();
            }
        },

        /**
         * Result message handler
         *
         * @param {object} response the worker message
         */
        onResult(response) {
            console.debug(`[${this.$options.name}] Result received`, response);
            this.$store.dispatch('updateLastUpload', Date.now());
        },

        /**
         * Error message handler
         *
         * @param {object} response the worker message
         */
        onError(response) {
            const data = response[response.type];
            this.showError();
            console.error(`[${this.$options.name}] An error occurred in worker space, for more details inspect the worker`, response);
            this.$emit('terminate', data.message);
        },

        /**
         * Canceled message handler
         *
         * @param {object} response the worker message
         */
        onCanceled(response) {
            const data = response[response.type];
            this.uploads = this.uploads.filter(u => u.id !== data.id);
            this.totalUploadsCount = this.uploads.length;
            if (this.totalUploadsCount === 0) {
                this.reset();
            }
        },

        /**
         * Info message handler
         *
         * @param {object} response the worker message
         */
        onInfo(response) {
            const data = response[response.type];
            this.uploads = isEmpty(data) ? data : unionBy(this.uploads, data, 'id');
            if (data.every(d => d.scope === 'closed-captions')) {
                // in closed captions context we can have multiple files for the same video, so we cannot union by asset id
                this.uploads = data.map((d, idx) => ({ ...d, key: `${d.id}_${idx}` }));
            }

            this.uploads = this.uploads.filter(u => !u.hideInUploader);

            this.totalUploadsCount = this.uploads.length;
        },

        /**
         * Scans the given message to find the event or content hub ID.
         *
         * @param {object} message the message to find the event ID from
         *
         * @returns {string|undefined} the ID of the originating event
         */
        findEvendId(message) {
            const event = get(message, 'payload.event', {});
            const data = message[message.type];
            return message.event_id || data.event_id || event.id || event._id;
        },

        /**
         * Resets the upload manager state
         */
        reset() {
            this.uploads = [];
            this.started = false;
            this.collapsed = false;
            this.activeUploads = 0;
            this.uploadedFiles = 0;
            this.totalUploadsCount = 0;
            this.visible = false;
        },


        /**
         * Sets the component started status
         */
        setStarted() {
            this.started = true;
            this.visible = true;
        },

        /**
         * Increases the uploading files number
         */
        increaseCounters() {
            this.activeUploads++;
        },

        /**
         * Decreases the uploading files number
         *
         * @param {object} [result] if successful an object containing the upload result
         */
        decreaseCounters(result) {
            this.activeUploads > 0 ? this.activeUploads-- : this.activeUploads = 0;

            if (this.activeUploads === 0) {
                this.$store.dispatch('updateUploadStatus', false);
            }

            if (result) {
                this.uploadedFiles++;
                console.debug(`[${this.$options.name}] Counters decreased, dispatching uploader-done event`, result);

                const event = new CustomEvent('uploader-done', { detail: { ...result } });
                window.dispatchEvent(event);
            } else {
                this.totalUploadsCount > 0 ? this.totalUploadsCount-- : this.totalUploadsCount = 0;
            }
        },

        /**
         * Decrease counters and shows an error to the user
         */
        decreaseAndShowError() {
            this.decreaseCounters();
            this.showError();
        },

        /**
         * Shows the error on the uploading file
         */
        showError() {
            this.setStarted();

            const $notify = get(window, 'BSTG.noty.$notify', this.$notify) || console.error;

            $notify({
                group: 'flashes',
                text: this.$i18n.t('media.uploads.messages.upload_failed').toString(),
                type: 'error',
                ignoreDuplicates: true
            });
        },

        /**
         * Asks the worker to interrupt the upload.
         * If no file is specified all uploads are cancelled.
         *
         * @param {object} [upload] the upload to abort
         */
        abort(upload) {
            if (upload) {
                this.workerScope.postMessage({ id: upload.id, command: 'abort' });

            } else {

                const count = this.totalUploadsCount;
                this.$modal.show('dialog', {
                    text: this.$tc('media.uploads.confirm_abort_all', count, [count]),
                    buttons: [
                        {
                            title: this.$t('general.cancel'),
                            default: true,
                            class: 'btn'
                        },
                        {
                            title: this.$t('general.confirm'),
                            handler: () => {
                                this.$modal.hide('dialog');
                                this.workerScope.postMessage('abort');
                            },
                            class: 'btn btn-danger'
                        }
                    ]
                });
            }
        }
    }

};
