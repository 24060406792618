/** @module Strings */

import { first, isObject, isString, last, cloneDeep } from 'lodash';
import { VALID_EMAIL_REGEX } from 'libs/utils/constants';

/** @const {RegEx} RE_IS_BINARY Used to detect binary string values. */
const RE_IS_BINARY = /^0b[01]+$/i;

/** @const {RegEx} RE_IS_OCTAL Used to detect octal string values. */
const RE_IS_OCTAL = /^0o[0-7]+$/i;

/** @const {RegEx} RE_IS_BAD_HEX Used to detect bad signed hexadecimal string values. */
const RE_IS_BAD_HEX = /^[-+]0x[0-9a-f]+$/i;

/**
 * Transforms first letter of the given value to a capital letter.
 *
 * @param {String} value the string to capitalize.
 * @return {String} the given string with a capitalized first letter.
 */
export function capitalize(value) {
    if (!isString(value)) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * Joins the values of the given array in a human readable way.
 *
 * @param {Array<String>} values the strings array to concatenate.
 * @param {String|import('vue-i18n').TranslateResult} [conjunction=''] the conjunction word to use for the last element.
 * @param {String} [separator=', '] the normal separator.
 *
 * @return {String} the joined values
 */
export function humanJoin(values = [], conjunction = '', separator = ', ') {
    const val = cloneDeep(values);
    const last = val.splice(-1, 1)[0];

    if (val.length < 1) {
        return last || '';
    }

    conjunction = (conjunction || '').length === 0 ? separator : ` ${conjunction} `;

    const concat = [];

    concat.push(val.join(separator));
    concat.push(`${conjunction}${last}`);

    return concat.join('');
}

/**
 * Strips HTML from string
 *
 * @param {String} content the HTML string to strip tags from.
 *
 * @returns {String} the whole text without HTML tags.
 */
export function stripHTML(content) {
    return (content || '')
        .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>/gi, '')
        .replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style\s*>/gi, '')
        .replace(/(<([^>]{1,500})>)/gi, '');
}

/**
 * Given an name string this filter returns a string representing the user's initials.
 *
 * @param {String} [name=''] the name to get initials for.
 *
 * @return {String} the capitalized user initials or an empty string
 */
export function initials(name = '') {
    const names = untokenize(name.trim()).split(/\s/);

    // Could happen if `user` is a single letter string, or the user object
    // only defines values without spaces.
    if (names.length === 1)
        return first(names).charAt(0).toLocaleUpperCase();

    // All other case
    return `${first(names).charAt(0)}${last(names).charAt(0)}`.toLocaleUpperCase();
}

/**
 * Sanitizes translation key by replacing dots with underscores
 *
 * @param {String} key translation key
 *
 * @return {String} sanitized key
 */
export function sanitizeTranslationKey(key) {
    return String(key).replace(/\./g, '_');
}

/**
 * Transforms the given value into a dasherized string.
 * i.e. "SomeStringTesting" becomes "some-string-testing".
 *
 * @param {String} [string=''] the string to transform case from.
 *
 * @returns {String} a dasherized version of the given string.
 */
export function dasherize(string = '') {
    return (string || '')
        .trim()
        .replace(/\s/g, '')
        .replace(/[A-Z]/g, (char, index) => (index !== 0 ? '-' : '') + char.toLowerCase())
        .replace(/--/g, '-');
}

/**
 * Removes all punctuation symbols from the string leaving only alphanumeric characters.
 *
 * @param {String} [string=''] the string to untokenize
 * @param {String} [replacement=''] the string to replace tokens with
 * @param {boolean} [trimTokens=false] if true it will remove tokens from the start and end of the word
 * @returns {String} the given string without tokens.
 */
export function untokenize(string = '', replacement = '', trimTokens = false) {
    const rep = replacement.length ? `( |${replacement}){2,}` : '( ){2,}';
    const adjacentChecker = replacement.length ? replacement : ' ';

    string = string
        // removes all characters except latin, greek and cyrillic letters and spaces
        .replace(/[^\w\s\u0370-\u03ff\u0400-\u04FF]|_/g, replacement)
        // remove all adjacents spaces
        .replace(new RegExp(rep, 'gm'), adjacentChecker);

    if (trimTokens) {
        string = string.replace(new RegExp(`^(${replacement})+|(${replacement})+$`, 'g'), '');
    }

    return string;
}

/**
 * Transform the given string into a one-word parameter suitable for URLs or CSS classes.
 *
 * @param {String} [string=''] the string to transform to a parameter
 *
 * @returns {String} the parameter's name string
 */
export function parameterize(string = '') {
    string = untokenize(string, '-', true); // Remove all punctuations and unwanted tokens
    string = string.trim().replace(/[\s|\-]+/gm, '-'); // Remove all spaces, all eventual new lines, and replace them with dashes
    string = dasherize(string); // Low-case dasherize

    return string;
}

/**
 * Get a hash code (in the java sense) of a string (used for caching for instance)
 * See // https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 *
 * @param {String} string the string to get the hash from
 *
 * @returns {Number} a 32bit hash version of the string
 */
export function hashCode(string) {
    let hash = 0, i, chr;

    if (!string || string.length === 0) {
        return hash;
    }
    for (i = 0; i < string.length; i++) {
        chr = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

/**
 * Transforms the given string to a human readable form.
 *
 * @param {String} string the string to humanize
 * @param {boolean} [capitalizeOutput=false] - Whether to capitalize the first letter of the output.
 *
 * @returns {String} a humanized representation of the given string.
 */
export function humanize(string = '', capitalizeOutput = false) {
    if (!string || !isString(string)) {
        return '';
    }
    const humanReadableString = untokenize(string, ' ');
    return capitalizeOutput ? capitalize(humanReadableString) : humanReadableString;
}

/**
 * Checks if the given string is a valid email format.
 *
 * @param {String|null} string the email to validate
 *
 * @returns {Boolean} whether the given string is a valid email or not
 */
export function isValidEmail(string = '') {
    return Boolean(string?.match(VALID_EMAIL_REGEX));
}

/**
 * Clamps string to a specific length but does not cut the sentence in the middle of a word
 *
 * @param {string} str the string to clamp
 * @returns {string} a truncated version of the string with ellipsis
 */
export function clamp(str = '', maxStringLength = 100) {

    if (!str) {
        return '';
    }

    if (str.length < maxStringLength) {
        return str;
    }

    const clampped = str.split(/\s/).reduce((clampped, word) => {
        const isShort = (clampped + word).length < maxStringLength;

        return isShort ? `${clampped} ${word}` : clampped;
    }, '');

    return `${clampped}...`;
}

/**
 * Transforms the given string to its snake case format
 *
 * @param {string} string the string to transform to snake case
 *
 * @returns {string} the snake case version of the given string
 */
export function toSnakeCase(string) {
    if (!string || !isString(string)) {
        return '';
    }

    return string
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(c => c.toLowerCase())
        .join('_');
}

/**
 * Shrinks a string padding it in the edges
 *
 * @param {string} string the string to shrink
 * @param {number} [max=16] the maximum string length
 * @param {number} [pad=6] the length of the edges to keep
 * @param {string} [separator='...'] the separator of the edges
 *
 * @returns {string} the shrinked version of the strings
 */
export function shrink(string, max = 16, pad = 6, separator = '...') {
    if (!string) {
        return '';
    }

    const len = string.length;

    if (len < max) {
        return string;
    }

    if (pad + pad + separator.length >= max) {
        return string;
    }

    return `${string.substring(0, pad)}${separator}${string.substring(len - pad, len)}`;
}

/**
 * Converts `value` to a number.
 *
 * @param {*} value The value to process.
 *
 * @returns {number} Returns the number.
 *
 * @example
 *
 * toNumber(Number.MIN_VALUE);
 * // => 5e-324
 *
 * toNumber(Infinity);
 * // => Infinity
 *
 * toNumber('3.2');
 * // => 3.2
 */
export function toNumber(value) {
    if (typeof value === 'number') {
        return value;
    }

    if (typeof value === 'symbol') {
        return NaN;
    }

    if (isObject(value)) {
        const other = typeof value.valueOf === 'function' ? value.valueOf() : value;
        value = isObject(other) ? (`${other} `) : other;
    }

    if (typeof value !== 'string') {
        return value === 0 ? value : +value;
    }

    value = value.trim();
    const isBinary = RE_IS_BINARY.test(value);

    if (isBinary || RE_IS_OCTAL.test(value)) {
        return parseInt(value.slice(2), isBinary ? 2 : 8);
    }

    if (RE_IS_BAD_HEX.test(value)) {
        return NaN;
    }

    const numeric = Number.parseFloat(value);
    const hasDecimal = numeric % 1 !== 0;

    if (hasDecimal) return numeric;

    return Number.parseInt(value);
}

/**
 * Removes the prefix 'org.couchdb.user:' from the given userId and returns the email.
 *
 * @param {string} userId - The user ID with the prefix 'org.couchdb.user:'.
 *
 * @returns {string} The email address extracted from the userId.
 */
export function emailFromUserId(userId) {
    return (userId || '').replace('org.couchdb.user:', '');
}


/**
 * Converts a type metadata object into a human-readable string representation.
 *
 * @param {Object} typeMetadata - The metadata object containing type information.
 * @param {string} type - The type to be humanized.
 * @param {boolean} [capitalize=false] - Whether to capitalize the first letter of the output.
 *
 * @returns {string} The human-readable representation of the type.
 */
export function humanizeType(typeMetadata, type, capitalize = false) {
    if (typeMetadata._type_representation) {
        return typeMetadata._type_representation;
    }

    return humanize(type, capitalize);
}
